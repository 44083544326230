import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'isEmptyObj' })
export class IsEmptyObjPipe implements PipeTransform {
  transform(value: any): boolean {
    if (!value) return true;
    if (typeof value !== 'object') return false;
    return Object.keys(value).length === 0;
  }
}

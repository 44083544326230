import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[fmeTemplate]',
})
export class TemplateDirective<T> {
  @Input()
  set fmeTemplateOf(name: string) {
    this.name = name;
  }
  name: string;

  constructor(public templateRef: TemplateRef<T>) {}
}

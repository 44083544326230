import { Injectable, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { AUTH_URLS } from '../../enums/auth.enum';

@Injectable({ providedIn: 'root' })
export class NavigationService implements OnDestroy {
  private history: string[] = [];
  navSub: Subscription;

  constructor(
    private router: Router,
    private location: Location,
  ) {
    console.log('INITIATED BACKBUTTON');
  }

  init() {
    this.navSub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
        localStorage.setItem(AUTH_URLS.PREV_URL_FOR_AUTH, event.urlAfterRedirects);
        console.log('NEW ROUTE', event.urlAfterRedirects);
      }
    });
  }

  getPreviousUrl(): string {
    return localStorage.getItem(AUTH_URLS.PREV_URL_FOR_AUTH);
  }

  back(): void {
    this.history.pop();
    if (this.history.length > 0) {
      this.location.back();
    } else {
      this.router.navigate(['..']);
    }
  }

  ngOnDestroy(): void {
    this.navSub.unsubscribe();
  }

}

import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { FmeAuthService } from '../services/auth.service';
import { AUTH_ENUM } from '../enums/auth.enum';
import { FmeSnackbarService } from '../components/fme-snackbar/fme-snackbar.service';
import { LANGUAGE_KEY } from '../enums/language.enum';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private fmeAuthService: FmeAuthService, private snackBar: FmeSnackbarService) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (request.url.includes('4tius-test.s3') || request.url.includes('4tius.s3')) {
            return next.handle(request);
        }
        if (this.fmeAuthService.isLoggedIn) {
            request = this.transformRequest(request);
        }
        return next.handle(request).pipe(tap({
            error: (err) => {
                if(err.status === 401) {
                    this.snackBar.open('error', 'Your session has expired! Please log in again')
                    this.fmeAuthService.logOut();
                }
            }
        }));
    }

    transformRequest(request: HttpRequest<any>) {
        const savedLang: string | undefined = localStorage.getItem(LANGUAGE_KEY.LANG);
        const lang: 'EN' | 'ES' | 'NB' = savedLang ? (savedLang.toUpperCase() as 'EN' | 'ES' | 'NB') : 'EN';
        const token: string | null = localStorage.getItem(AUTH_ENUM.FME_AUTH_TOKEN);
        const authorization: string = `Bearer ${token}`;
        return request.clone({
            setHeaders: {
                Authorization: authorization,
                "x-user-lang": lang
            },
        });
    }

}

import { NgModule } from '@angular/core';

import { FmeSnackbarComponent } from './fme-snackbar.component';
import { MatIconModule } from '@angular/material/icon';
import { FmeSharedModule } from '../../fme-shared.module';

@NgModule({
    declarations: [FmeSnackbarComponent],
    imports: [MatIconModule, FmeSharedModule],
    exports: [FmeSnackbarComponent],
    providers: [],
})
export class FmeSnackbarModule {}

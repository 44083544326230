import { Pipe, PipeTransform, QueryList, TemplateRef } from "@angular/core";
import { TemplateDirective } from "../directives/template.directive";

@Pipe({
    name: 'findTemplate',
  })
  export class FindTemplatePipe implements PipeTransform {
    transform(
      templates: (TemplateDirective<object>[] | QueryList<TemplateDirective<object>>)[],
      name: string,
    ): TemplateRef<object> {
      if (!templates || !name) {
        return null;
      }
  
      for (const array of templates) {
        if (!array) {
          continue;
        }
  
        const columnTemplate: TemplateDirective<object> = array.find(
          (item: TemplateDirective<object>) => item.name === name,
        );
        if (columnTemplate) {
          return columnTemplate.templateRef;
        }
      }
  
      return null;
    }
  }
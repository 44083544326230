import { inject } from "@angular/core";
import { Router } from "@angular/router";
import { map } from "rxjs";
import { FmeAuthService } from "../services/auth.service";
import { NavigationService } from "../services/common/navigation.service";

export const LoggedInGuard = () => {
    const authService = inject(FmeAuthService);
    const router = inject(Router);
    const navService = inject(NavigationService);
    if (authService.isLoggedIn) {
        const prevUrl = navService.getPreviousUrl();
        if (prevUrl) {
            router.navigate([prevUrl]);
        }
        return false;
    }
    return true;

}

import { NgModule } from '@angular/core';
import { LifestyleFormValidPipe } from './form/lifestyle-form-valid.pipe';
import { ObjHaveValidFieldsPipe } from './objHaveValidFields.pipe';
import { FindTemplatePipe } from './findTemplate.pipe';
import { IsEmptyObjPipe } from './isEmptyObj.pipe';
import { ShowReccuringDatePipe } from './show-reccuring-date.pipe';
import { MapCurrencyPipe } from './map-currency.pipe';

@NgModule({
  declarations: [LifestyleFormValidPipe, ObjHaveValidFieldsPipe, FindTemplatePipe, IsEmptyObjPipe, ShowReccuringDatePipe, MapCurrencyPipe],
  exports: [LifestyleFormValidPipe, ObjHaveValidFieldsPipe, FindTemplatePipe, IsEmptyObjPipe, ShowReccuringDatePipe, MapCurrencyPipe],
})
export class FmePipesModule {}

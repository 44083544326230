import { Injectable } from '@angular/core';
import { FmeSnackbarComponent } from './fme-snackbar.component';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class FmeSnackbarService {

    constructor(readonly snackBar: MatSnackBar) {}

    open(type: "error" | "warn" | "info" | "success", message: string) {
        const options: MatSnackBarConfig = {
            panelClass: `fme-snackbar-panel${type === 'error' ? '-error' : ''}`,
            data: {
                type: type,
                message: message
            },
            duration: 5000,
            verticalPosition: 'bottom',
            horizontalPosition: 'left'
        };
        this.snackBar.openFromComponent(FmeSnackbarComponent, options);
    }

}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LogInCredentials } from '../../interfaces/auth-credentials.interface';
import { HttpClient } from '@angular/common/http';
import { FmeAdmin, FmeCoordinator, FmeProfessional } from '../../interfaces/users.interface';
import { FmeApiResponse } from '../../interfaces/fme-api-response.interface';

@Injectable({
    providedIn: 'root'
})
export class FmeAuthApiService {
    baseUrl: string = environment.BASE_API_URL;

    constructor(private http: HttpClient) {}

    logIn(credentials: LogInCredentials): Observable<FmeApiResponse<{token: string}>> {
        return this.http.post<any>(`${this.baseUrl}/auth/local`, credentials);
    }

    getUserInfo(): Observable<FmeAdmin> {
        return this.http.get<any>(`${this.baseUrl}/users/me`).pipe(
            map<FmeApiResponse<FmeAdmin>, FmeAdmin>((response: FmeApiResponse<FmeAdmin>) => response.data)
        );
    }
    getUserSettings(): Observable<FmeProfessional | FmeCoordinator> {
        return this.http.get<any>(`${this.baseUrl}/users/me/settings`).pipe(
            map<FmeApiResponse<FmeProfessional | FmeCoordinator>, FmeProfessional | FmeCoordinator>((response: FmeApiResponse<FmeProfessional | FmeCoordinator>) => response.data)
        );
    }
    getAccountToken(id: string): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}/auth/by-user`, { userId: id });
    }
}

import { Pipe, PipeTransform } from '@angular/core';
import { ClientLifestyleInfo } from '../../interfaces/client-data.interface';

@Pipe({ name: 'lifestyleFormValid' })
export class LifestyleFormValidPipe implements PipeTransform {
  transform(form: ClientLifestyleInfo) {
    return Boolean(
      form?.domesticPhysicalLoad &&
        form?.activityGroup &&
        form?.dailyTransport &&
        form.activityChangedLastThreeMonths &&
        form?.inactivityTime &&
        form?.participateSportEvents &&
        form?.activityPossibilities,
    );
  }
}

import { Pipe, PipeTransform } from "@angular/core";
import { License } from "../interfaces/license.model";

@Pipe({name: 'showReccuringDate'})

export class ShowReccuringDatePipe implements PipeTransform {
    transform(value: License): string {
        if(!value || !value?.price?.recurring) return '';
        const reccuring = value.price.recurring;
        const interval = reccuring.interval.at(0).toUpperCase() + reccuring.interval.slice(1);
        if(interval === 'Year' && reccuring.intervalCount === 1) return 'annual';
        return `${reccuring.intervalCount} ${interval}${reccuring.intervalCount > 1 ? 's' : ''}`
        // if(value === null || value === undefined) return '';
        // else return value === '0000-00-00' ? 'Every day' : value;
    }
}
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { AUTH_ENUM } from '../enums/auth.enum';
import { LogInCredentials } from '../interfaces/auth-credentials.interface';
import { FmeAuthApiService } from './api/auth-api.service';
import { FmeAdmin, FmeCoordinator, FmeProfessional } from '../interfaces/users.interface';
import { FmeApiResponse } from '../interfaces/fme-api-response.interface';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class FmeAuthService {

    private currentUser$: BehaviorSubject<FmeAdmin | null> = new BehaviorSubject<FmeAdmin | null>(null);
    private currentUserSettings$: BehaviorSubject<FmeCoordinator | FmeProfessional | FmeAdmin | null> = new BehaviorSubject<FmeCoordinator | FmeProfessional | null>(null);

    get isLoggedIn(): boolean {
        const token: string | null = localStorage.getItem(AUTH_ENUM.FME_AUTH_TOKEN);
        return !!token;
    }

    get token(): string | null {
        const token: string | null = localStorage.getItem(AUTH_ENUM.FME_AUTH_TOKEN);
        return token;
    }

    get secondaryToken(): string | null {
        const token: string | null = localStorage.getItem(AUTH_ENUM.FME_SECONDARY_AUTH_TOKEN);
        return token;
    }

    get userFromStorage(): any | null {
        if (!localStorage.getItem(AUTH_ENUM.FME_AUTH_USER)) return;
        const user: any | null = JSON.parse(localStorage.getItem(AUTH_ENUM.FME_AUTH_USER)!);
        return user;
    }

    get currentUser(): Observable<any> {
        return this.currentUser$.asObservable();
    }
    get currentUserSettings(): Observable<FmeCoordinator | FmeProfessional | FmeAdmin> {
        return this.currentUserSettings$.asObservable();
    }

    constructor(private fmeAuthApiService: FmeAuthApiService, private router: Router) {}

    // main

    logIn(credentials: LogInCredentials): Observable<FmeApiResponse<{token: string}>> {
        return this.fmeAuthApiService.logIn(credentials);
    }

    logOut() {
        console.log('logout')
        this.currentUser$.next(null);
        this.currentUserSettings$.next(null);
        localStorage.removeItem(AUTH_ENUM.FME_AUTH_USER);
        localStorage.removeItem(AUTH_ENUM.FME_AUTH_TOKEN);
        localStorage.removeItem(AUTH_ENUM.FME_SECONDARY_AUTH_TOKEN);
        this.router.navigate(['/auth']);
    }

    getUserInfo(): Observable<FmeAdmin> {
        return this.fmeAuthApiService.getUserInfo();
    }

    getUserSettings(): Observable<FmeProfessional | FmeCoordinator | FmeAdmin> {
        return this.fmeAuthApiService.getUserSettings().pipe(
            tap((data: FmeProfessional | FmeCoordinator | FmeAdmin) => {
                if (!data) return;
                localStorage.setItem(AUTH_ENUM.FME_AUTH_USER, JSON.stringify(data));
                this.currentUserSettings$.next(data);
            })
        );
    }

    getAccountToken(id: string) {
        return this.fmeAuthApiService.getAccountToken(id);
    }

    removeSecondaryToken() {
        const secToken: string = this.secondaryToken;
        if (!secToken) return;
        this.setToken(secToken);
        localStorage.removeItem(AUTH_ENUM.FME_SECONDARY_AUTH_TOKEN);
    }

    replaceMainToken(token: string) {
        const mainToken: string = this.token;
        if (!mainToken) return;
        this.setToken(token);
        this.setSecondaryToken(mainToken);
    }

    // utils

    setUser(user: any) {
        if (!user) return;
        localStorage.setItem(AUTH_ENUM.FME_AUTH_USER, JSON.stringify(user));
        this.currentUser$.next(user);
    }

    setToken(token: string) {
        localStorage.setItem(AUTH_ENUM.FME_AUTH_TOKEN, token);
    }

    setSecondaryToken(token: string) {
        localStorage.setItem(AUTH_ENUM.FME_SECONDARY_AUTH_TOKEN, token);
    }
}

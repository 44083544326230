export enum AUTH_ENUM {
    FME_AUTH_TOKEN = 'fme-token',
    FME_SECONDARY_AUTH_TOKEN = 'fme-secondary-token',
    FME_AUTH_USER = 'fme-user',
}

export enum AUTH_ERROR {
    E_UNAUTHENTICATED = 'E_UNAUTHENTICATED',
}

export enum AUTH_URLS {
    PREV_URL_FOR_AUTH = 'fme-previous-route',
}

export enum AUTH_FLOW {
    REMEMBER_ME = 'fme-remember-me',
    CREDS = 'fme-creds'
}

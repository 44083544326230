import { Component, OnInit, Inject } from '@angular/core';
import { Animations } from '../../animations/select-input.animation';
import {MatSnackBarRef, MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';

@Component({
    selector: 'fme-snackbar',
    templateUrl: './fme-snackbar.component.html',
    styleUrls: ['./fme-snackbar.component.scss'],
    animations: [Animations.animations]
})
export class FmeSnackbarComponent implements OnInit {

    constructor(
        public snackBarRef: MatSnackBarRef<FmeSnackbarComponent>,
        @Inject(MAT_SNACK_BAR_DATA) public data: {type: "error" | "warn" | "info" | "success", message: string,},
        ) {}

    ngOnInit(): void {
        console.log(this.data);
    }

    closeSnackbar() {
        this.snackBarRef.dismiss();
    }


}

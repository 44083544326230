import { Pipe, PipeTransform } from "@angular/core";
import { CURRENCY_CODE } from "../enums/api/currency-code.enum";

@Pipe({name: 'mapCurrency'})

export class MapCurrencyPipe implements PipeTransform {
    transform(currency: CURRENCY_CODE): string {
        if(currency === CURRENCY_CODE.EUR) {
            return '€';
        }
        if(currency === CURRENCY_CODE.USD) {
            return '$';
        }
        return '';
    }
}
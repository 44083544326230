import { inject } from "@angular/core";
import { Router } from "@angular/router";
import { map } from "rxjs";
import { FmeAuthService } from "../services/auth.service";

export const AuthGuard = () => {
    const authService = inject(FmeAuthService);
    const router = inject(Router);

    if (!authService.isLoggedIn) {
        router.navigateByUrl('/auth');
        return false;
    }
    return true;

    return authService.currentUser.pipe(
        map((currentUser: any) => {
            console.log('AUTH GUARD USER: ', currentUser);
            if (!currentUser) {
                router.navigateByUrl('/auth');
                return false;
            }
            return true;
        })
    )
}
